@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.CustomSectionHeader {
  display: flex;
  flex-direction: column;
  gap: tokens.bpk-spacing-md();

  .Heading {
    h2 {
      @include breakpoints.bpk-breakpoint-small-tablet {
        @include typography.bpk-hero-6;
      }
    }
  }

  .Subheading {
    p {
      @include breakpoints.bpk-breakpoint-small-tablet {
        @include typography.bpk-editorial-3;
      }
    }
  }
}
