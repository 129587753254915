/* stylelint-disable order/order */
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.HighlightedArticles {
  .ArticlesScrollContainer {
    &--with-margin-top {
      margin-top: tokens.bpk-spacing-base();
    }

    .ArticlesRow {
      display: flex;
      gap: tokens.bpk-spacing-base();
    }

    .ArticleItem {
      min-width: 80%;
    }
  }

  .ArticlesGrid {
    display: grid;
    column-gap: tokens.bpk-spacing-lg();
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    row-gap: tokens.bpk-spacing-xxl();

    &--with-margin-top {
      margin-top: tokens.bpk-spacing-xxl();
    }
  }
}
