@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.ArticleCard {
  position: relative;

  &:hover {
    .Image img {
      transform: scale(1.1);
      filter: saturate(1.5) contrast(0.75) brightness(0.75);
    }
  }

  .ArticleLink {
    position: absolute;
    z-index: 1; // To ensure the link sits on-top of card content
    inset: 0;
  }

  .Image {
    margin-bottom: tokens.bpk-spacing-base();

    &__cardSet {
      margin-bottom: tokens.bpk-spacing-lg();

      @include breakpoints.bpk-breakpoint-small-tablet {
        margin-bottom: tokens.bpk-spacing-md();
      }
    }

    img {
      width: 100%;
      height: 100%;
      transition:
        transform tokens.$bpk-duration-base cubic-bezier(0.18, 0.89, 0.32, 1.28),
        filter tokens.$bpk-duration-base cubic-bezier(0.18, 0.89, 0.32, 1.28);
      object-fit: cover;
    }
  }

  .ArticleTitle {
    &__gridArticle h3 {
      margin-bottom: tokens.bpk-spacing-sm();

      @include typography.bpk-heading-4;
    }

    &__cardSet h3 {
      margin-bottom: tokens.bpk-spacing-md();

      @include typography.bpk-hero-5;

      @include breakpoints.bpk-breakpoint-small-tablet {
        @include typography.bpk-hero-6;
      }
    }

    &__highlightedArticle h3 {
      margin-bottom: tokens.bpk-spacing-base();
      text-wrap: wrap;
      white-space: normal;

      @include typography.bpk-heading-2;

      @include breakpoints.bpk-breakpoint-small-tablet {
        @include typography.bpk-heading-3;
      }
    }
  }

  .DateAuthor {
    display: flex;

    time {
      color: tokens.$bpk-text-secondary-day;

      &::after {
        content: '\2022';
        padding: 0 tokens.bpk-spacing-sm();
      }
    }

    .AuthorLink {
      position: relative;
      z-index: 1; // To ensure the author link sits on-top of the card link

      @include typography.bpk-footnote;
    }
  }

  .Body {
    width: 85%;
    margin-top: tokens.bpk-spacing-lg();
    color: tokens.$bpk-text-secondary-day;

    @include breakpoints.bpk-breakpoint-small-tablet {
      margin-top: tokens.bpk-spacing-base();

      p {
        @include typography.bpk-body-default;
      }
    }
  }

  .Cta {
    position: relative;
    z-index: 1; // To ensure the author link sits on-top of the card link
    margin-top: tokens.bpk-spacing-base();

    @include breakpoints.bpk-breakpoint-small-tablet {
      margin-top: tokens.bpk-spacing-md();
    }
  }
}
